import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import { Typography } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import clsx from "clsx";
import dayjs from "dayjs";
import * as React from "react";
import { memo, useEffect, useState } from "react";
import { ObjectParam, StringParam, useQueryParam, withDefault } from "use-query-params";
import { GetNodeGroupsNodes, GetNodeGroupsNodesResponse } from "../../../api/fetcher";
import Tab, { TABS_CONTAINER_CLASS_NAME } from "../../../components/Tab";
import { EventPoint } from "../../../pages/Overview/PolicyTuning/Diagnostics/utils";
import PropertiesChip from "../../../utils/PropertiesChip";
import Dialog from "../../Dialog";
import CustomTooltip from "../../Tooltip";
import NotScalingDownTooltipContent from "../NotScalingDownTooltipContent";
import useGetNodeIconAndTitle from "../useGetNodeIconAndTitle";
import {
  hasScaleDownWarning,
  NODE_OVERVIEW_DATES_URL_PARAM,
  NodeOverviewTabs,
  NodeStatsResponseTypeSchema,
  useNodesViewPeriodQueryParams,
} from "../Utils";
import NodesAnalytics from "./NodesAnalytics";
import PodsTable from "./PodsTable";
import ResourcesOverTimeCharts from "./ResourcesOverTimeCharts";
import { ViewPeriodOptions } from "./SelectViewPeriod";
import TopSection from "./TopSection";

interface Props {
  selectedNode: NodeStatsResponseTypeSchema | undefined;
  selectedNodeName: string | undefined;
  setSelectedNodeName: React.Dispatch<React.SetStateAction<string | undefined>>;
  isOpen: boolean;
}

export const NODE_OVERVIEW_SELECTED_TAB_QUERY_KEY = "nodeOverviewSelectedTab";
const MIN_TAB_CONTENT_HEIGHT = 770;

const NodeOverviewContainer = memo(({ selectedNode, selectedNodeName, setSelectedNodeName, isOpen }: Props) => {
  const [, setEmptyEventArray] = useState<EventPoint[] | undefined>(undefined);
  const [from, setFrom] = useState<number>(dayjs().subtract(1, "day").unix());
  const [to, setTo] = useState<number>(dayjs().unix());
  const [selectedViewPeriod, setSelectedViewPeriod] = useNodesViewPeriodQueryParams();
  const [, setDates] = useQueryParam(NODE_OVERVIEW_DATES_URL_PARAM, ObjectParam);

  const [selectedTab, setSelectedTab] = useQueryParam(
    NODE_OVERVIEW_SELECTED_TAB_QUERY_KEY,
    withDefault(StringParam, NodeOverviewTabs.Overview)
  );

  const { queryFn, queryKey } = GetNodeGroupsNodes();
  const { data, isLoading, error, isFetching } = useQuery<GetNodeGroupsNodesResponse, Error>({
    queryKey: [queryKey, selectedNodeName, from, to],
    queryFn: () => queryFn({ name: selectedNodeName, from, to }),
    enabled: !!selectedNodeName,
    keepPreviousData: true,
  });

  useEffect(() => {
    setFrom(dayjs().subtract(Number(selectedViewPeriod), "hour").unix());
    setTo(dayjs().unix());
    if (!selectedViewPeriod) {
      setSelectedViewPeriod(ViewPeriodOptions["1 day"]);
    }
  }, [selectedViewPeriod]);

  const annotations = data?.nodeInfo?.annotations;
  const labels = data?.nodeInfo?.labels;
  const tags = data?.nodeInfo?.tags;
  const blockedByScaleDown = hasScaleDownWarning(selectedNode);
  const { icon, title } = useGetNodeIconAndTitle(selectedNode?.limitScaleDownSeverity);
  const blockedByScaleDownValue = blockedByScaleDown && (
    <CustomTooltip
      maxWidth={500}
      title={
        <NotScalingDownTooltipContent
          nodeGroup={selectedNode?.nodeGroup ?? ""}
          limitScaleDownMessage={selectedNode?.limitScaleDownMessage ?? ""}
          limitScaleDownReason={selectedNode?.limitScaleDownReason}
          limitScaleDownAction={selectedNode?.limitScaleDownAction}
          blockingOwner={selectedNode?.blockingOwner}
          blockingName={selectedNode?.blockingName}
          blockingMessage={selectedNode?.blockingMessage}
          nodeReasonDetails={selectedNode?.nodeReasonDetails}
          nodeGroups={undefined}
          title={title}
          icon={icon}
        />
      }
    >
      {icon}
    </CustomTooltip>
  );

  const DialogTitleAndChips = (
    <div className="flex items-center gap-2">
      <Typography fontWeight={500}>Node overview</Typography>
      <ArrowRightAltIcon />
      <span>{selectedNodeName}</span>
      <PropertiesChip topic="Node" title="Annotations" items={annotations ?? []} isLoading={isLoading} />
      <PropertiesChip topic="Node" title="Labels" items={labels ?? []} isLoading={isLoading} />
    </div>
  );

  const handleClose = () => {
    setDates(undefined);
    setSelectedNodeName(undefined);
    setSelectedTab(undefined);
  };

  return (
    <Dialog
      isOpen={isOpen && !!selectedNodeName}
      onClose={handleClose}
      title={DialogTitleAndChips}
      dialogContentStyle={{
        padding: "0px",
      }}
      minWidth="1200px"
    >
      <div className="flex flex-col gap-[10px] pt-[10px] bg-background-chipHover">
        <div className="mt-[6px] pt-[30px]">
          <div className="bg-white px-[20px] py-[30px] relative">
            <div className={clsx(TABS_CONTAINER_CLASS_NAME, "w-full top-[-36px] left-0")}>
              {Object.entries(NodeOverviewTabs).map(([key, value]) => {
                return (
                  <Tab
                    key={key}
                    isSelected={selectedTab === value}
                    onClick={() => {
                      setSelectedTab(value);
                    }}
                    name={value}
                    dataTestId={`node-overview-${key}-tab`}
                    hasSelectedUnderline
                  />
                );
              })}
            </div>
            {selectedTab === NodeOverviewTabs.Overview && (
              <>
                <div className="flex flex-col justify-center items-center min-w-[600px] min-h-[400px] gap-4 mb-5">
                  <TopSection
                    selectedViewPeriod={selectedViewPeriod}
                    setSelectedViewPeriod={setSelectedViewPeriod}
                    tags={tags?.filter((tag) => tag !== "unschedulable")}
                    status={data?.nodeInfo?.status}
                    schedulable={tags?.includes("unschedulable") ? false : true}
                    blocked={blockedByScaleDownValue}
                    blockedByTitle={title}
                    cost={selectedNode?.cost ?? 0}
                  />
                  <ResourcesOverTimeCharts
                    from={from}
                    to={to}
                    setEmptyEventArray={setEmptyEventArray}
                    selectedViewPeriod={selectedViewPeriod}
                    data={data}
                    isLoading={isLoading}
                    error={error}
                    isFetching={isFetching}
                  />
                  {/* DIAGNOSTICS removed due to RD-2946
                     <div className="w-full">
                       <NodeDiagnosticsContainer
                         name={selectedNodeName}
                         endDate={to}
                         startDate={from}
                         selectedViewPeriod={selectedViewPeriod}
                         emptyEventArray={emptyEventArray}
                       />
                     </div>
                   )} */}
                </div>
                <PodsTable rows={data?.pods} isLoading={isLoading}></PodsTable>
              </>
            )}

            {selectedTab === NodeOverviewTabs.Analytics && (
              <div style={{ minHeight: MIN_TAB_CONTENT_HEIGHT }}>
                <NodesAnalytics
                  selectedNode={selectedNode}
                  nodesData={data}
                  isLoading={isLoading}
                  error={error}
                  isFetching={isFetching}
                  setEmptyEventArray={setEmptyEventArray}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </Dialog>
  );
});

export default NodeOverviewContainer;
