import { Typography } from "@mui/material";
import clsx from "clsx";
import dayjs from "dayjs";
import React, { useRef } from "react";
import CalendarIcon from "../Icons/CalendarIcon";
import { DEFAULT_DATE_TIME_FORMAT } from "../utils/formatterUtils";
import { adjustedDayjs } from "../utils/dateAndTimeUtils";

const INPUT_TIME_FORMAT = "YYYY-MM-DDTHH:mm";
const CALENDAR_ICON_SIZE = 14;
const DIMENSIONS = "w-[180px] h-[34px]";

const defaultRenderDateValue = (date: number) => dayjs(date).format(DEFAULT_DATE_TIME_FORMAT);

interface Props {
  date?: number;
  setDate: (date: number) => void;
  format?: string;
  label?: React.ReactNode;
  minDate?: number;
  maxDate?: number;
  renderDateValue?: (date: number) => string;
  inputValueFactor?: number;
}

const DatePicker = ({
  date,
  setDate,
  label,
  minDate,
  maxDate,
  renderDateValue = defaultRenderDateValue,
  inputValueFactor = 1,
}: Props) => {
  const ref = useRef<HTMLInputElement>(null);
  return (
    <div className="relative">
      <input
        ref={ref}
        type="datetime-local"
        value={date ? dayjs(date * inputValueFactor).format(INPUT_TIME_FORMAT) : undefined}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          setDate(adjustedDayjs(e.target.value).unix());
        }}
        className={clsx(DIMENSIONS)}
        min={minDate ? dayjs(minDate * inputValueFactor).format(INPUT_TIME_FORMAT) : undefined}
        max={maxDate ? dayjs(maxDate * inputValueFactor).format(INPUT_TIME_FORMAT) : undefined}
      />
      <div
        onClick={() => ref.current?.showPicker()}
        className={clsx(
          DIMENSIONS,
          "absolute top-0 left-0 cursor-pointer px-2 border border-border hover:border-strongBorder rounded-lg flex items-center bg-white"
        )}
      >
        <Typography variant="body2">{date ? renderDateValue(date) : "Select date"}</Typography>
        <div className="grow flex justify-end">
          <CalendarIcon width={CALENDAR_ICON_SIZE} height={CALENDAR_ICON_SIZE} />
        </div>
        {label && <div className="bg-white top-[-10px] absolute px-2 text-[12px] text-text-lightBlack">{label}</div>}
      </div>
    </div>
  );
};

export default DatePicker;
